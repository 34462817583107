import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../styles/ChatList.css';
import '../styles/App.css';
import { AppContext } from '../context/appContext';
import { AuthContext } from '../context/authContext';
import { useNavigate } from 'react-router-dom';
import {FaCheck, FaEdit, FaTimes, FaTrash} from 'react-icons/fa';

const ChatList = ({ onConversationClick, onNewChatClicked, onDeleteConversation, onTitleUpdate }) => {
    const { chatList, selectedChat } = useContext(AppContext);
    const { isLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();

    const [editingChatId, setEditingChatId] = useState(null);
    const [newTitle, setNewTitle] = useState('');

    const titleInputRef = useRef(null);

    useEffect(() => {
        if (editingChatId && titleInputRef.current) {
            titleInputRef.current.focus();
            const len = titleInputRef.current.value.length;
            titleInputRef.current.setSelectionRange(len, len);
        }
    }, [editingChatId]);

    const startEditing = (id, title) => {
        setEditingChatId(id);
        setNewTitle(title);
    };

    const stopEditing = () => {
        setEditingChatId(null);
    };

    const handleTitleUpdate = () => {
        console.log("Updating title" + editingChatId + " title: " + newTitle);
        onTitleUpdate(newTitle, editingChatId);
        stopEditing();
    };

    const sortedChats = Object.values(chatList)
        .filter(conv => conv._id !== -1 && conv._id !== undefined)
        .sort((a, b) => new Date(b.date) - new Date(a.date));

    const groupedByDate = sortedChats.reduce((acc, conv) => {
        let dateObj = new Date(conv.date);

        if (isNaN(dateObj.getTime())) {
            dateObj = new Date();
        }

        const dateStr = new Intl.DateTimeFormat('en-US', { weekday: 'short', month: 'short', day: 'numeric' }).format(dateObj);
        if (!acc[dateStr]) {
            acc[dateStr] = [];
        }

        acc[dateStr].push(conv);
        return acc;
    }, {});

    return (
        <div className="chat-list">
            {isLoggedIn &&
                <ul className="chat-list-inner">
                    {Object.entries(groupedByDate).map(([date, conversations]) => (
                        <React.Fragment key={date}>
                            <div className="date-list">
                                <li className="date-label">{date}</li>
                                {conversations.map((conv) => (
                                    <li
                                        key={conv._id}
                                        className={`${conv._id === selectedChat ? 'selected-chat' : 'chat-item'}`}
                                        onClick={(e) => {
                                            if (e.target.tagName !== 'INPUT' && e.target.tagName !== 'BUTTON') {
                                                if (!editingChatId) {
                                                    onConversationClick(conv._id);
                                                } else {
                                                    stopEditing();
                                                }
                                            }
                                        }}
                                    >

                                        {editingChatId === conv._id ? (
                                            <>
                                                <input
                                                    className="chat-title title-input"
                                                    value={newTitle}
                                                    onChange={(e) => setNewTitle(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleTitleUpdate();
                                                        }
                                                    }}
                                                    ref={titleInputRef}
                                                />
                                                <button className={`chat-control`} onClick={(e) => { e.stopPropagation(); handleTitleUpdate(); }}><FaCheck /></button>
                                                <button className={`chat-control`} onClick={(e) => { e.stopPropagation(); stopEditing(); }}><FaTimes /></button>
                                            </>
                                        ) : (
                                            <>
                                                <div className="chat-title">{conv.isUserTitle ? conv.userTitle : conv.title}</div>
                                                <div className={`${conv._id === selectedChat ? 'chat-controls-selected' : 'chat-controls'}`}>
                                                    <div className="gradient-chat-control chat-control"></div>
                                                    <div className={'chat-control-holder'}>
                                                        <button
                                                            className={`chat-edit-title chat-control`}
                                                            onClick={(e) => { e.stopPropagation(); startEditing(conv._id,
                                                                conv.isUserTitle ? conv.userTitle : conv.title); }}
                                                        >
                                                            <FaEdit />
                                                        </button>

                                                        <button
                                                            className={`chat-delete chat-control`}
                                                            onClick={(e) => { e.stopPropagation(); onDeleteConversation(conv._id); }}
                                                        >
                                                            <FaTrash />
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </li>
                                ))}
                            </div>
                        </React.Fragment>
                    ))}
                    {/*Spacer div with 30px height*/}
                    <div style={{ height: '30px' }}></div>
                </ul>
            }
            <div className="gradient"></div>
            {isLoggedIn && <button className="add-chat-button" onClick={() => onNewChatClicked()}>New Chat</button>}
            {!isLoggedIn &&
                <div className="history-sign-in body-message">
                    Sign in to view your previous conversations.
                    <button className="login-button" onClick={() => navigate('/', { state: { signIn: true } })}>Sign In</button>
                    <br />
                    <div className="body-message">Don't have an account?</div>
                    <button className="get-started-button" onClick={() => navigate('/', { state: { createAccount: true } })}>Create Account</button>
                </div>
            }
        </div>
    );
};

ChatList.propTypes = {
    onConversationClick: PropTypes.func.isRequired,
    onNewChatClicked: PropTypes.func.isRequired,
    onDeleteConversation: PropTypes.func.isRequired,
    onTitleUpdate: PropTypes.func.isRequired
};

export default ChatList;
