import React from 'react';
import NavBar from "../components/navBar";

function Settings() {
    return (
        <div>
            <NavBar />
            <h1>Settings Page</h1>
            {/* Your page content here */}
        </div>
    );
}

export default Settings;