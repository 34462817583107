import React, {useCallback, useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import { useIntl } from 'react-intl';
import debounce from 'lodash.debounce';
import { io } from 'socket.io-client';

// Local imports
import ChatBubble from './chatBubble';
import { WS_URL, SYSTEM_PROMPT } from '../constants/constants';
import '../styles/ChatContainer.css';
import { AppContext } from '../context/appContext';
import { AuthContext } from '../context/authContext';
import { useChatService } from '../services/chatServices';
import {FaArrowUp} from "react-icons/fa";
import app from "../pages/app";

function ChatContainer() {
    // State declarations
    const [containerHeight, setContainerHeight] = useState(window.innerHeight);
    const [remainingSpace, setRemainingSpace] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [bubbleHeights, setBubbleHeights] = useState([]);
    const [ws, setWs] = useState(null);
    const [textAreaHeight, setTextAreaHeight] = useState(25);

    // Refs for persistent values
    const scrollRef = useRef(null);
    const textAreaRef = useRef(null);
    const context = useContext(AppContext);
    const contextRef = useRef(context);

    const [startY, setStartY] = useState(0);
    // Internationalization hook
    const { formatMessage: f } = useIntl();

    // Context and service hooks
    const { chatList, visibleChatList, updateMessageData, selectedChat, setSelectedChat, updateLatestMessage,
        selectedModel, hasScrolled, setHasScrolled, userScroll, setUserScroll, applyChatList } = useContext(AppContext);
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
    const { newChat, getChat, getChatList } = useChatService();

    // Effect to keep contextRef updated
    useEffect(() => {
        contextRef.current = context;
    }, [context]);

    // WebSocket connection setup
    useEffect(() => {
        const socket = io(WS_URL, { autoConnect: true, withCredentials: true });
        setWs(socket);

        socket.on('connect', () => console.log("Connected to WebSocket server"));
        socket.on('message', (message) => receivedMessageFromBackend(message));
        socket.on('error', (error) => console.error(`WebSocket Error: ${error}`));
        socket.on('disconnect', () => console.log("WebSocket connection closed"));

        return () => socket.disconnect();
    }, []);

    // Effect for window resize and scroll event listeners
    useEffect(() => {
        const debouncedHandleResize = debounce(() => setContainerHeight(window.innerHeight), 300);
        const scroll = scrollRef.current;
        window.addEventListener('resize', debouncedHandleResize);
        // scroll.addEventListener('scroll', handleScroll);
      //  window.addEventListener("mousewheel", handleScroll, false);

        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
            // scroll.removeEventListener('scroll', handleScroll);
           /// scroll.removeEventListener("mousewheel", handleScroll);

        };
    }, []);

    const handleTouchStart = (event) => {
        const touch = event.touches[0];
        setStartY(touch.clientY);
    };

    const handleTouchMove = (event) => {
        const currentY = event.touches[0].clientY;
        let scrollUp = false;
        if (currentY > startY) {
            scrollUp = true;
        } else if (currentY < startY) {
            scrollUp = false;
        }

        handleScroll(scrollUp)

        // Logic for handling the scroll, e.g., updating the state, scrolling content, etc.
    };

    const handleWheel = (event) => {
        setTimeout(() => {

            let scrollUp = false;
            if (event.deltaY < 0) {
                scrollUp = true;
            } else if (event.deltaY > 0) {
                scrollUp = false;
            }

            handleScroll(scrollUp)
        }, 100); // A minimal delay

    };


    // Handle window scroll
    const handleScroll = (isScrollUp) => {
        // Check if scrolled to the bottom
        const { current } = scrollRef;
        if (current) {
            // Check scroll direction
            const { scrollTop, scrollHeight, clientHeight } = current;
            const atBottom = Math.abs((scrollHeight - scrollTop) - clientHeight) < 120;

            if (isScrollUp) {
                setHasScrolled(true);
            }

            if (!isScrollUp && atBottom) {
                setHasScrolled(false);
            }
        }
    };

    const receivedMessageFromBackend = async (event) => {
        // Extracting necessary values from context and refs
        const {
            updateMessageData,
            updateLatestMessage,
            selectedChat,
            setSelectedChat,
            updateChatTitle,
            hasScrolled
        } = contextRef.current;

        // Handling different event types
        switch (event.type) {
            case "text_stream":
                handleTextStreamEvent(event, selectedChat, hasScrolled,
                    updateMessageData, updateLatestMessage);
                break;
            case "stream_end":
                // Handle stream end logic here if needed
                break;
            case "tile_generated":
                console.log("Tile generated", JSON.stringify(event));
                await handleTileGeneratedEvent(event, updateChatTitle);
                break;
            default:
                // Handling for other types of events
                break;
        }
    };

    // Helper function to handle 'text_stream' type events
    const handleTextStreamEvent = async (event, selectedChat, hasScrolled,
                                         updateMessageData, updateLatestMessage) => {
        const {sentChat, reply: latestChat, num, selectedChat: eventSelectedChat} = event;

        setSelectedChat(eventSelectedChat);

        if (num === 0) {
            updateMessageData(eventSelectedChat, [
                {message: sentChat, isSender: true},
                {message: latestChat, isSender: false}
            ]);
        } else {
            updateLatestMessage(eventSelectedChat, {message: latestChat, isSender: false});
        }

        scrollToBottomIfNotScrolled(hasScrolled);
    };

    // Helper function to handle 'tile_generated' type events
    const handleTileGeneratedEvent = async (event, updateChatTitle) => {
        const { selectedChat: eventSelectedChat, title } = event;
        updateChatTitle(eventSelectedChat, title);
        await getChat(eventSelectedChat);
        setSelectedChat(eventSelectedChat);
    };

    // Helper function to scroll to bottom if the user hasn't scrolled
    const scrollToBottomIfNotScrolled = (hasScrolled) => {
        if (!hasScrolled) {
            const { current } = scrollRef;
            if (current) current.scrollTop = current.scrollHeight;
            setUserScroll(false);
        }
    };

    // Send user input via WebSocket
    const sendMessageToBackend = async (userInput) => {
        if (ws) {
            const { selectedChat, selectedModel } = contextRef.current;
            const modelName = selectedModel ? selectedModel.name : 'local';
            const request = {
                preferredModel: modelName,
                selectedChat: selectedChat,
                user_input: userInput,
            };

            ws.emit('message', JSON.stringify(request));
            setHasScrolled(false);
        }
    };

    // Set bubble height in state
    const handleSetHeight = useCallback((index, height) => {
        setBubbleHeights((prev) => {
            const newHeights = [...prev];
            newHeights[index] = height;
            return newHeights;
        });

    }, []);


    // Handle form submission
    const handleInputSubmit = async (e) => {
        e.preventDefault();
        if (inputValue.trim() === '') return;
        setInputValue('');
        sendMessageToBackend(inputValue);
    };

    const resizeTextarea = () => {
        textAreaRef.current.style.height = 'auto'; // Reset height to recalculate
        const scrollHeight = textAreaRef.current.scrollHeight; // Get the scroll height of the textarea
        const minHeight = 25; // Minimum height for the textarea
        const maxHeight = 300; // Maximum height for the textarea

        // Set the height with constraints
        const newHeight = Math.max(minHeight, Math.min(scrollHeight, maxHeight)) - 10;
        textAreaRef.current.style.height = `${newHeight }px`; // Apply the new height
        setTextAreaHeight(newHeight); // Use your state updater to set the new height if necessary

        updateEmptySpace(); // Update empty space if part of your logic
    };

    const updateEmptySpace = () => {
        // Calculate total height of chat bubbles and remaining space
        const totalBubbleHeight = bubbleHeights.reduce((acc, cur, idx) => {
            if (chatList[selectedChat] === undefined || !chatList[selectedChat].messages) return acc;
            if (idx <= chatList[selectedChat].messages.length - 1) {
                return acc + cur;
            }
            return acc;
        }, 0);

        const paddingWithInput = 140 + textAreaHeight;

        setRemainingSpace(Math.max(containerHeight - paddingWithInput - totalBubbleHeight, 0));
        applyChatList();
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevent default Enter key behavior (new line)
            // Call the function to handle form submission
            handleInputSubmit(e); // You might need to adjust this if your submission logic is different
        } else {
            //resizeTextarea();
        }
    };

    useLayoutEffect(() => {
        resizeTextarea()
    }, [chatList, bubbleHeights, inputValue, selectedChat]);


    // Return null if no chat is selected
    // if (!visibleChatList[selectedChat]) {
    //     return null;
    // }

    const simulateEnterKeyPress = () => {
        const enterKeyPressEvent = new KeyboardEvent('keydown', {
            key: 'Enter',
            code: 'Enter',
            keyCode: 13,
            which: 13,
            shiftKey: false,
            bubbles: true,
        });

        // Dispatch the event on the textarea
        document.querySelector('.text-input').dispatchEvent(enterKeyPressEvent);
    };

    // Component render
    return (
        <div>
            {visibleChatList[selectedChat] &&
            <div className='chat-scroll' ref={scrollRef}   onTouchStart={handleTouchStart}
                 onTouchMove={handleTouchMove} onWheel={handleWheel} style={{ height: `${containerHeight - 93}px`,
                overflowY: 'auto', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
                <div style={{ marginTop: '60px', scrollPaddingTop: '50px' }}>
                    <div style={{height: `${remainingSpace}px` }}></div>
                    {visibleChatList[selectedChat].messages.map((item, index) => (
                        <ChatBubble
                            key={index}
                            index={index}
                            message={item.message}
                            isSender={item.isSender}
                            setHeight={handleSetHeight}
                        />
                    ))}
                    <div key={textAreaHeight} style={{height:  `${textAreaHeight - 25}px`}}></div>
                </div>
            </div>}


            <form onSubmit={handleInputSubmit} className="form-container">
                <div className="input-wrapper">
                  <textarea
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      placeholder={f({ id: 'type_your_message' })}
                      className="text-input"
                      ref={textAreaRef}
                      rows={1} // You can adjust the number of rows as needed
                      style={{
                          resize: 'none', // Prevents the user from resizing the textarea
                          overflowY: 'auto', // Adds a scrollbar when the text exceeds the area
                          // Any additional styling to make it look similar to the original input
                      }}
                  />
                    <button type="button" className="submit-button" onClick={simulateEnterKeyPress}>
                        <FaArrowUp />
                    </button>

                </div>
            </form>
        </div>
    );
}

export default ChatContainer;
