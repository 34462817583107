import React, {useContext, useEffect, useState} from 'react';
import LightingEffects from '../components/lightingEffects';
import ChatContainer from "../components/chatContainer";
import NavBar from "../components/navBar";
import ChatList from "../components/chatList";
import { ReactComponent as HistoryIcon} from '../assets/history.svg';
import {useChatService} from "../services/chatServices";
import {AppContext} from "../context/appContext";
import ModelDropDown from "../components/modelDropDown";

function Chat() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isChatListVisible, setChatListVisible] = useState(false);
    const { newChat, getChat, getChatList, deleteChat, updateChatTitle } = useChatService();
    const { chatList, setChatList, setSelectedChat } = useContext(AppContext)
    const lightColors = [
        0.396754, 0.514917, 0.973445,
        0.693871, 0.838798, 0.973445,
        1.000000, 1,1,
        0.5, 0.8, 0.3,
        1.0, 0.8, 0.3,
    ];

    const start = -200;
    const end = 200;
    const step = (end - start) / 2;
    const lights = [];
    // Loop over and create the lights array
    for (let i = 0; i < 3; i++) {
        const position = [start + step * i, 0];
        const color = lightColors.slice(i * 3, i * 3 + 3);
        lights.push({ position, color });
    }

    const toggleChatList = () => {
        setChatListVisible(!isChatListVisible);
    };

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // Disable body scroll when the component is mounted
        document.body.style.overflow = 'hidden';

        getChatList().then(() => {
            // get today's date
        })

        // Re-enable body scroll when the component is unmounted
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);  // Empty dependency array ensures this runs only on mount and unmount

    const handleConversationClick = (id) => {
        // Perform some action
        getChat(id)
        setChatListVisible(false)
    };

    const handleDeleteChat = async (id) => {

        // Perform some action
        const success = await deleteChat(id)
        getChatList()
        console.log("Reload after delete", id)
    };


    const handleUpdateChatTitle = async (id, title) => {

        // Perform some action
        const success = await updateChatTitle(id, title)
        getChatList()
        console.log("Reload after title update", id)
    };


    const handleNewChat = (index) => {
        console.log(`Clicked new chat:`);

        newChat().then((res) => {
            // get today's date
            getChatList()
        })
        // Perform some action
    };

    // Change the max width in chatlist.css too
    const shouldHideChatList = windowWidth <= 1300 && !isChatListVisible;

    return (
        <div className={"chat-holder"}>
            <NavBar class={"nav-bar-parent"}/>


            <div
                className="chat-list-container"
                style={{ visibility: shouldHideChatList ? 'hidden' : 'visible' }}>
                <ChatList conversations={[]} onConversationClick={handleConversationClick} onNewChatClicked={handleNewChat}
                          onDeleteConversation={handleDeleteChat} onTitleUpdate={handleUpdateChatTitle}/>
            </div>
            <div className={isChatListVisible ? 'chat-controls-holder-no-background' : 'chat-controls-holder'}>
                <div className={"chat-controls-container"}>
                    <button className={isChatListVisible ? 'minimize-button' : 'minimize-button-icon'} onClick={toggleChatList}>
                        {isChatListVisible ? 'X' :  <HistoryIcon style={{width:'30px'}}/>}
                    </button>

                    {!isChatListVisible && <ModelDropDown />}
                </div>
            </div>
            <ChatContainer chatData={[]} className={"chat-container-width"}/>
            <LightingEffects lights={lights}/>
        </div>
    );
}

export default Chat;