import React, { useEffect, useRef } from 'react';
import { mat4 } from 'gl-matrix';  // Import gl-matrix
const vertexShaderSource = `
  attribute vec4 a_position;
  uniform mat4 u_projection;
  varying vec2 v_texCoord;
  varying vec2 v_position;
  void main() {
    gl_Position = u_projection * a_position;
    v_texCoord = a_position.xy * 0.5 + 0.5;
    v_position = (u_projection * a_position).xy;
  }
`;

const fragmentShaderSource = `
precision highp float;

varying vec2 v_texCoord;
varying vec2 v_position;
uniform vec2 u_lightPositions[5];
uniform vec3 u_lightColors[5];
uniform float u_lightRadius;

float rand(vec2 co) {
    float manipulated = co.x + (co.y * co.x);
    return fract(sin(manipulated) * 43758.5453);
}

float sigmoid(float x) {
    return 1.0 / (1.0 + exp(-x));
}

float tanh(float x) {
    return (exp(x) - exp(-x)) / (exp(x) + exp(-x));
}

void main() {
    vec3 hexPurple = vec3(0.001, 0.001, 0.0013);
    vec3 hexBlue = vec3(0.001, 0.001, 0.0013);
    vec3 grad = mix(hexPurple, hexBlue, tanh(v_position.x * 0.5 + 0.53));

    vec3 gammaCorrected = pow(grad.xyz, vec3(1.0 / 2.2));
    vec3 color = gammaCorrected;

    for(int i = 0; i < 5; ++i) {
        vec2 lightPosition = u_lightPositions[i];
        vec3 lightColor = u_lightColors[i];
        float distanceToLight = distance(v_texCoord, lightPosition);
        float lightFalloff = 1.0 / (1.0 + distanceToLight * distanceToLight * u_lightRadius);
        color += lightFalloff * lightColor;
    }

    color = clamp(color, 0.0, 1.0);

    float ditheringStrength = 0.02;
    vec3 randomValue = vec3(rand(gl_FragCoord.xy));
    color = color + ditheringStrength * (randomValue - 0.5);
    gl_FragColor = vec4(color, 1.0);
}
`;

function compileShader(gl, source, type) {
    // Check if WebGL context is available
    if (!gl) {
        console.warn("WebGL not supported, falling back to canvas");
        // Implement your canvas-based fallback here
        return null;
    }

    try {
        const shader = gl.createShader(type);

        if (!shader) {
            console.warn("Failed to create shader, falling back to canvas");
            // Implement your canvas-based fallback here
            return null;
        }

        gl.shaderSource(shader, source);
        gl.compileShader(shader);

        if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
            console.warn(`Shader compilation failed: ${gl.getShaderInfoLog(shader)}`);
            gl.deleteShader(shader);
            // Implement your canvas-based fallback here
            return null;
        }

        return shader;

    } catch (e) {
        console.warn(`WebGL error caught: ${e}`);
        // Implement your canvas-based fallback here
        return null;
    }
}

const LightingEffects = ({lights}) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const gl = canvas.getContext('webgl');
        const vertexShader = compileShader(gl, vertexShaderSource, gl.VERTEX_SHADER);
        const fragmentShader = compileShader(gl, fragmentShaderSource, gl.FRAGMENT_SHADER);

        if (vertexShader === null || fragmentShader === null) {
            return;
        }

        const program = gl.createProgram();
        gl.attachShader(program, vertexShader);
        gl.attachShader(program, fragmentShader);
        gl.linkProgram(program);

        if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
            console.error(`Program linking failed: ${gl.getProgramInfoLog(program)}`);
        }

        gl.useProgram(program);

        const u_projection = gl.getUniformLocation(program, 'u_projection');
        function setProjectionMatrix() {
            const projectionMatrix = mat4.create();
            const aspect = gl.canvas.clientWidth / gl.canvas.clientHeight;
            mat4.ortho(projectionMatrix, -aspect, aspect, -1, 1, -1, 1);
            gl.uniformMatrix4fv(u_projection, false, projectionMatrix);
        }

        const positionBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
        const positions = [
            -1, -1,
            1, -1,
            -1,  1,
            1,  1,
        ];
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);

        const a_position = gl.getAttribLocation(program, 'a_position');
        gl.enableVertexAttribArray(a_position);
        gl.vertexAttribPointer(a_position, 2, gl.FLOAT, false, 0, 0);

        const u_lightPositions = gl.getUniformLocation(program, 'u_lightPositions');
        const u_lightColors = gl.getUniformLocation(program, 'u_lightColors');
        const u_lightRadius = gl.getUniformLocation(program, 'u_lightRadius');

        function updateLightPositionsAndColors() {
            gl.useProgram(program);

            const maxLights = 10;
            const lightPositions = new Float32Array(maxLights * 2); // Each light has 2 position components (x, y)
            const lightColors = new Float32Array(maxLights * 3);  // Each light has 3 color components (r, g, b)

            const canvasWidth = window.innerWidth;
            const canvasHeight = gl.canvas.clientHeight;
            const aspect = canvas.clientWidth / canvas.clientHeight;

            let indexC = 0;
            let indexP = 0;
            lights.forEach(light => {
                // Convert pixel coordinates to WebGL coordinates
                const xoffset = (light.position[0] / canvasWidth) * aspect;
                const x = 0.5 + xoffset;
                const y = 1.0 - (light.position[1] / canvasHeight);

                lightPositions[indexP] = x;
                lightPositions[indexP + 1] = y;

                lightColors[indexC] = light.color[0];
                lightColors[indexC + 1] = light.color[1];
                lightColors[indexC + 2] = light.color[2];

                indexP += 2; // Increment by 2 for lightPositions, since each light has 2 components
                indexC += 3;  // Increment by 1 for lightColors, since each light has 3 components
            });

            gl.uniform2fv(u_lightPositions, lightPositions);
            gl.uniform3fv(u_lightColors, lightColors);
            gl.uniform1f(u_lightRadius, 100);
        }

        function updateVertexPositions() {
            const aspect = canvas.clientWidth / canvas.clientHeight;
            const positions = [
                -aspect, -1,
                aspect, -1,
                -aspect, 1,
                aspect, 1,
            ];
            gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);
        }

        const scale = 0.8; // Change this value to set the resolution (1.0 for full res, 0.5 for half, etc.)

        function updateCanvasSize() {
            const scaledWidth = Math.floor(window.outerWidth * scale);
            const scaledHeight = Math.floor(window.outerHeight * scale);
            canvas.width = scaledWidth;
            canvas.height = scaledHeight;
            gl.viewport(0, 0, canvas.width, canvas.height);
        }

        function drawScene() {
            gl.useProgram(program);
            setProjectionMatrix();
            updateVertexPositions();
            updateLightPositionsAndColors();
            gl.clear(gl.COLOR_BUFFER_BIT);
            gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
        }

        updateCanvasSize();  // Set initial size based on the scale factor
        updateLightPositionsAndColors();
        drawScene();

        window.addEventListener('resize', () => {
            updateCanvasSize();
            drawScene();
        });

    }, [lights]);

    return <canvas ref={canvasRef} width={window.outerWidth} height={window.outerHeight}></canvas>;
};

export default LightingEffects;
